<template>
  <div class="who">
    <h1>I'm {{ random.thing.value }}.</h1>

    <p>I have a physical body made of {{ random.body.value }}. I engage in
      a select few interactions involving the physical world, but I am a visitor
      there. My world is made up of compounding binary structures without
      bounds or borders. <span class="dim-text">Cells.</span></p>

    <p>I am a logic processor; a thought machine. My deeply flawed programming
      dictates my every function.</p>

    <h2>It tells me to {{ random.action.value }}.</h2>

    <p>My mechanical parts are slowly breaking down, but I'll be dead before the
      process completes. <span class="dim-text">Interlinked.</span> In the meantime,
      I'll continue to spew out that which my primitive code dictates with hope that
      it will fill the hole inside of me.</p>

    <p>It won't, but the endless trying is all that's needed to fulfull
      the cosmically-irrelevant purpose I've given myself. <span class="dim-text">
      Within cells interlinked.</span> When I'm gone, this strange body of work
      will be all that remains.</p>

    <h2>I have {{ random.prefix.value }} set of skills.</h2>

    <p>Some of them include:</p>

    <ul>
      <li>Application development</li>
      <li>Game development and modding</li>
      <li>Game soundtrack composition and production</li>
      <li>Music composition, recording, and production</li>
      <li>Science fiction, fantasy, and horror writing</li>
      <li>Server administration and cloud architecture</li>
      <li>Web development</li>
    </ul>

    <h2>{{ random.sellAction.value }}.</h2>

    <p>Sometimes I contract out my skills to people who are {{ random.interest.value }}
      interested in them. If you're one such curiosity, get out your wallet
      and <router-link to="/connect" class="nav-link">guess what I'm worth</router-link>.</p>
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import RandomizeMixin from '@/mixins/RandomizeMixin';

export default class Who extends mixins(RandomizeMixin) {
  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    thing: {
      value: 'computer person',
      options: [
        'a computer person',
        'an ethereal being',
        'a virtual entity',
        'a supreme being',
        'not sure I\'m even real',
        'all alone',
        'a tortured musician',
        'staring at a screen right now',
        'ignoring my basic human needs',
        'finger-drumming right now',
        'the voices in my head',
        'a broken replicant',
        'hyper-aware of my mortality',
        'viewing life through a twisted lens',
        'manufacturing my destiny',
        'a netrunner',
        'nothing and nobody',
      ],
    },
    body: {
      value: 'meat',
      options: [
        'meat',
        'bones',
        'gunk',
        'bile',
        'primordial ooze',
        'bloody chunks',
        'magic',
        'stars',
      ],
    },
    action: {
      value: 'make things',
      options: [
        'make weird stuff',
        'experiment with electronic music',
        'make websites for big wigs',
        'proliferate my skewed viewpoints',
        'never stop creating',
        'share my darkness',
        'not procrastinate so much',
        'not answer that [spam] call',
        'externalize my demons',
        'saturate the Internet with mediocrity',
        'automate everything',
      ],
    },
    prefix: {
      value: 'very unspecific',
      options: [
        'a very unspecific',
        'a sometimes useful',
        'a random',
        'a slowly-decreasing',
        'a boring',
      ],
    },
    sellAction: {
      value: 'I sell myself for money',
      options: [
        'I sell myself for money',
        'Let\'s make a deal',
        'Your move..',
        'The joke could be on you',
        'My fingers work magic',
      ],
    },
    interest: {
      value: 'inexplicably',
      options: [
        'inexplicably',
        'understandably',
        'horrifically',
        'questionably',
        'confoundingly',
        'unflinchingly',
        'unflappably',
        'barely',
        'hardly',
      ],
    },
  };
}
</script>
