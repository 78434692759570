
import { mixins } from 'vue-class-component';
import RandomizeMixin from '@/mixins/RandomizeMixin';

export default class Who extends mixins(RandomizeMixin) {
  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    thing: {
      value: 'computer person',
      options: [
        'a computer person',
        'an ethereal being',
        'a virtual entity',
        'a supreme being',
        'not sure I\'m even real',
        'all alone',
        'a tortured musician',
        'staring at a screen right now',
        'ignoring my basic human needs',
        'finger-drumming right now',
        'the voices in my head',
        'a broken replicant',
        'hyper-aware of my mortality',
        'viewing life through a twisted lens',
        'manufacturing my destiny',
        'a netrunner',
        'nothing and nobody',
      ],
    },
    body: {
      value: 'meat',
      options: [
        'meat',
        'bones',
        'gunk',
        'bile',
        'primordial ooze',
        'bloody chunks',
        'magic',
        'stars',
      ],
    },
    action: {
      value: 'make things',
      options: [
        'make weird stuff',
        'experiment with electronic music',
        'make websites for big wigs',
        'proliferate my skewed viewpoints',
        'never stop creating',
        'share my darkness',
        'not procrastinate so much',
        'not answer that [spam] call',
        'externalize my demons',
        'saturate the Internet with mediocrity',
        'automate everything',
      ],
    },
    prefix: {
      value: 'very unspecific',
      options: [
        'a very unspecific',
        'a sometimes useful',
        'a random',
        'a slowly-decreasing',
        'a boring',
      ],
    },
    sellAction: {
      value: 'I sell myself for money',
      options: [
        'I sell myself for money',
        'Let\'s make a deal',
        'Your move..',
        'The joke could be on you',
        'My fingers work magic',
      ],
    },
    interest: {
      value: 'inexplicably',
      options: [
        'inexplicably',
        'understandably',
        'horrifically',
        'questionably',
        'confoundingly',
        'unflinchingly',
        'unflappably',
        'barely',
        'hardly',
      ],
    },
  };
}
